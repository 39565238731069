import { useStaticQuery, graphql } from "gatsby";

export const PattiStaticQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PattiStaticQuery {
        allMarkdownRemark(
          filter: { fields: { slug: { regex: "/about/patti/" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 240)
                  }
                }
              }
              htmlAst
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map((e) => e.node);
};
