/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure patti component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Accolade from "../about/accolade";
import Description from "../about/description";
import Name from "../about/name";
import Photo from "../about/photo";
import Role from "../about/role";
import { PattiStaticQuery } from "../../hooks/pattiQuery";
import Socials from "../socials/socials";
import * as AboutService from "../../utils/about.service";

// Styles
import { accolades, intro, patti } from "./patti.module.css";
import { aligned, between, flex } from "../../styles/global.module.css";

const classNames = require("classnames");

export default function Patti({ location }) {
  const posts = PattiStaticQuery();
  const experience = AboutService.getHtmlAstByFileName(posts, "experience");
  const distinctions = AboutService.getHtmlAstByFileName(posts, "distinctions");
  const professional = AboutService.getHtmlAstByFileName(posts, "professional");
  const education = AboutService.getHtmlAstByFileName(posts, "education");
  const description = AboutService.getDescriptionHtmlAst(posts);
  const publications = AboutService.getHtmlAstByFileName(
    posts,
    "publications"
  );
  const image = AboutService.getPhotoImage(posts);
  const presentations = AboutService.getHtmlAstByFileName(
    posts,
    "presentations"
  );
  const role = AboutService.getRoleHtmlAst(posts);
  const title = AboutService.getNameHtmlAst(posts);
  return (
    <>
      <div className={classNames(flex, patti)}>
        <Photo alt={"Patti"} image={image} />
        <div className={intro}>
          <div className={classNames(flex, aligned, between)}>
            <Name>{title}</Name>
            <Socials linkedin location={location} email twitter />
          </div>
          <Role>{role}</Role>
          <Description>{description}</Description>
        </div>
      </div>
      <div className={classNames(flex, accolades)}>
        <Accolade>{experience}</Accolade>
        <Accolade>{publications}</Accolade>
        <Accolade>{presentations}</Accolade>
        <Accolade>{distinctions}</Accolade>
        <Accolade>{professional}</Accolade>
        <Accolade>{education}</Accolade>
      </div>
    </>
  );
}
