import { useStaticQuery, graphql } from "gatsby";
import * as AboutService from "../utils/about.service";

export const PeopleStaticQuery = () => {
  const { allFile } = useStaticQuery(
    graphql`
      query PeopleStaticQuery {
        allFile(filter: { relativeDirectory: { regex: "/about/people/" } }) {
          distinct(field: relativeDirectory)
        }
      }
    `
  );
  return AboutService.listPeople(allFile.distinct);
};
