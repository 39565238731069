import { useStaticQuery, graphql } from "gatsby";

export const SocialsStaticQuery = (docPath) => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query SocialsStaticQuery {
        allSitePage {
          edges {
            node {
              context {
                heading {
                  depth
                  value
                }
              }
              path
            }
          }
        }
      }
    `
  );
  return allSitePage.edges
    .map((e) => e.node)
    .filter((n) => n.path === docPath)[0];
};
