/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure socials component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { SocialsStaticQuery } from "../../hooks/socialsQuery";

// Images
import imgFacebook from "../../../images/socials/facebook.png";
import imgLinkedIn from "../../../images/socials/linkedIn.png";
import imgTwitter from "../../../images/socials/twitter.png";

// Styles
import { socials as socialsStyles } from "./socials.module.css";
import { aligned, flex } from "../../styles/global.module.css";

const classNames = require("classnames");

const urlLS = "https://licensesure.biz";

export default function Socials({
  className,
  email,
  facebook,
  linkedin,
  location,
  share,
  twitter,
}) {
  const pathname = location?.pathname || "";
  const seo = pathname ? SocialsStaticQuery(pathname) : "";
  const encodedUrl = pathname ? encodeURIComponent(urlLS + pathname) : "";
  let heading = "";
  if (seo) {
    const { context } = seo || {};
    const headings = context.heading || [];
    heading = headings[0]?.value || "";
  }
  return (
    <div className={classNames(flex, aligned, socialsStyles, className)}>
      {linkedin && (
        <a
          href={getLinkedInLink(share, encodedUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Linkedin" src={imgLinkedIn} />
        </a>
      )}
      {email && (
        <a
          href={getMailLink(share, pathname, heading)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className={"material-icons-round"}>email</i>
        </a>
      )}
      {facebook && (
        <a
          href={getFacebookLink(share, encodedUrl)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Facebook" src={imgFacebook} />
        </a>
      )}
      {twitter && (
        <a
          href={getTwitterLink(share, encodedUrl, heading)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img alt="Twitter" src={imgTwitter} />
        </a>
      )}
    </div>
  );
}

function getFacebookLink(socialShare, encodedUrl) {
  if (!socialShare) {
    return "https://www.facebook.com/LicenseSureLLC";
  }
  return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
}

function getLinkedInLink(socialShare, encodedUrl) {
  if (!socialShare) {
    return "https://www.linkedin.com/company/licensesure-llc";
  }
  return `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&source=License Sure`;
}

function getMailLink(socialShare, emailBody = "", emailSubject) {
  if (!socialShare) {
    return "mailto:info@licensesure.biz";
  }
  return `mailto:?subject=${emailSubject}&body=${urlLS}${emailBody}`;
}

function getTwitterLink(share, encodedUrl = "", twitterText) {
  if (!share) {
    return "https://twitter.com/licensesure";
  }
  return `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${twitterText}&via=LicenseSure`;
}
