/*
 * LicenseSure
 * https://licensesure.biz
 *
 * Basic about service.
 */

// Template variables
const orderPeopleBySeniority = [
  "mariana",
  "ximena",
  "maria",
  "rhiannon",
  "marrisa",
  "ashley",
  "jahnavi",
  "corina",
  "alisha",
  "maya",
];

/**
 * Returns a post specified by file name.
 * e.g. file name "photo" will return the post /about/patti/photo.
 *
 * @param posts
 * @param fileName
 */
export function filterPostsByFileName(posts, fileName) {
  if (!posts) {
    return;
  }

  return posts.find((post) => post.fields.slug.includes(`/${fileName}`));
}

/**
 * Returns description post html ast.
 *
 * @param postsByName
 */
export function getDescriptionHtmlAst(postsByName) {
  const post = filterPostsByFileName(postsByName, "description");
  return post?.htmlAst;
}

/**
 * Returns a post html ast specified by file name.
 *
 * @param postsByName
 * @param fileName
 */
export function getHtmlAstByFileName(postsByName, fileName) {
  const post = filterPostsByFileName(postsByName, fileName);
  return post?.htmlAst;
}

/**
 * Returns LinkedIn post html ast.
 *
 * @param postsByName
 */
export function getLinkedInHtmlAst(postsByName) {
  const post = filterPostsByFileName(postsByName, "linkedIn");
  return post?.htmlAst;
}

/**
 * Returns name post html ast.
 *
 * @param postsByName
 */
export function getNameHtmlAst(postsByName) {
  const post = filterPostsByFileName(postsByName, "name");
  return post?.htmlAst;
}

/**
 * Returns photo post image (from frontmatter).
 *
 * @param postsByName
 */
export function getPhotoImage(postsByName) {
  const post = filterPostsByFileName(postsByName, "photo");
  return post?.frontmatter?.image;
}

/**
 * Returns role post html ast.
 *
 * @param postsByName
 */
export function getRoleHtmlAst(postsByName) {
  const post = filterPostsByFileName(postsByName, "role");
  return post?.htmlAst;
}

/**
 * Returns a list of people.
 *
 * @param relativeDirs
 */
export function listPeople(relativeDirs) {
  const splitPeopleBy = "about/people/";
  const people = getPeople(relativeDirs, splitPeopleBy);
  /* Reorder the team by seniority. */
  return reorderPeople(people);
}

/**
 * Returns a list of people derived from their corresponding relative directory.
 *
 * @param relativeDirs
 * @param splitBy
 */
function getPeople(relativeDirs, splitBy) {
  if (!relativeDirs) {
    return [];
  }

  return relativeDirs.map((relativeDir) => {
    return relativeDir.split(splitBy)[1];
  });
}

/**
 * Reorders the team by seniority.
 *
 * @param people
 * @returns {*}
 */
function reorderPeople(people) {
  if (!people) {
    return people;
  }

  /* Reorder the list of people by seniority. */
  return orderPeopleBySeniority.reduce((acc, person) => {
    /* Add the person to the list if they exist in the /content/people/ directory. */
    if (people.includes(person)) {
      acc.push(person);
    }

    return acc;
  }, []);
}
