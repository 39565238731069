/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure people component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { PeopleStaticQuery } from "../../hooks/peopleQuery";
import Person from "../person/person";

// Styles
import { people as peopleStyles } from "./people.module.css";
import { between, flex, wrap } from "../../styles/global.module.css";

let classNames = require("classnames");

export default function People() {
  const people = PeopleStaticQuery();
  return (
    <div className={classNames(flex, wrap, between, peopleStyles)}>
      {people
        ? people.map((person, i) => <Person key={i} person={person} />)
        : null}
    </div>
  );
}
