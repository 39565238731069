import { useStaticQuery, graphql } from "gatsby";

export const PersonStaticQuery = (person) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PersonStaticQuery {
        allMarkdownRemark(filter: { fields: { slug: { regex: "/about/" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                image {
                  childImageSharp {
                    gatsbyImageData(width: 178)
                  }
                }
              }
              htmlAst
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges
    .map((e) => e.node)
    .filter((n) => n.fields.slug.includes(`/${person}/`));
};
