/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure about role component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Markdown from "../markdown/markdown";

// Styles
import { role } from "./role.module.css";

class Role extends React.Component {
  render() {
    const { children } = this.props;
    return <Markdown className={role}>{children}</Markdown>;
  }
}

export default Role;
