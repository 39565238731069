/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure publications component.
 */

// Core dependencies
import React from "react";

// App dependencies
import { PublicationsStaticQuery } from "../../hooks/publicationsQuery";
import Markdown from "../markdown/markdown";

export default function Publications() {
  const post = PublicationsStaticQuery()[0];
  const { htmlAst } = post;
  return <Markdown>{htmlAst}</Markdown>;
}
