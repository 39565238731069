/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure about description component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Markdown from "../markdown/markdown";

// Styles
import { description } from "./description.module.css";

const classNames = require("classnames");

class Description extends React.Component {
  render() {
    const { children, className } = this.props;
    return (
      <Markdown className={classNames(description, className)}>
        {children}
      </Markdown>
    );
  }
}

export default Description;
