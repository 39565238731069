/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure - about page.
 */

// Core dependencies
import React from "react";

// App dependencies
import Layout from "../components/layout";
import Patti from "../components/patti/patti";
import People from "../components/people/people";
import Publications from "../components/publications/publications";

// Styles
import { container } from "../templates/contentTemplate.module.css";

class About extends React.Component {
  render() {
    const { location } = this.props;
    return (
      <Layout>
        <div className={container}>
          <h1>About</h1>
          <Patti location={location} />
          <h2>The LicenseSure Team</h2>
          <People />
          <Publications />
        </div>
      </Layout>
    );
  }
}

export default About;
