/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure about photo component.
 */

// Core dependencies
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

// Styles
import { photo } from "./photo.module.css";

class Photo extends React.Component {
  render() {
    const { alt, image } = this.props;
    return (
      image && (
        <GatsbyImage alt={alt} className={photo} image={getImage(image)} />
      )
    );
  }
}

export default Photo;
