/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure about LinkedIn component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Markdown from "../markdown/markdown";

// Styles
import { linkedIn } from "./linkedIn.module.css";

class LinkedIn extends React.Component {
  render() {
    const { children } = this.props;
    return <Markdown className={linkedIn}>{children}</Markdown>;
  }
}

export default LinkedIn;
