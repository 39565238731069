import { useStaticQuery, graphql } from "gatsby";

export const PublicationsStaticQuery = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PublicationsStaticQuery {
        allMarkdownRemark(
          filter: { fields: { slug: { regex: "/about/publications/" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              htmlAst
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map((e) => e.node);
};
