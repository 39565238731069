/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure person component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Description from "../about/description";
import LinkedIn from "../about/linkedIn";
import Name from "../about/name";
import Photo from "../about/photo";
import Role from "../about/role";
import { PersonStaticQuery } from "../../hooks/personQuery";
import * as AboutService from "../../utils/about.service";

// Styles
import { person as personDescription } from "../about/description.module.css";
import { intro, person as personStyles } from "./person.module.css";
import { flex } from "../../styles/global.module.css";

export default function Person({ person }) {
  const posts = PersonStaticQuery(person);
  const title = AboutService.getNameHtmlAst(posts);
  const role = AboutService.getRoleHtmlAst(posts);
  const description = AboutService.getDescriptionHtmlAst(posts);
  const image = AboutService.getPhotoImage(posts);
  const linkedIn = AboutService.getLinkedInHtmlAst(posts);
  return (
    <div className={personStyles}>
      <div className={flex}>
        <Photo alt={person} image={image} />
        <div className={intro}>
          <Name>{title}</Name>
          {role ? <Role>{role}</Role> : null}
          {linkedIn ? <LinkedIn>{linkedIn}</LinkedIn> : ""}
        </div>
      </div>
      <Description className={personDescription}>{description}</Description>
    </div>
  );
}
