/*
 * LicenseSure
 * https://licensesure.biz
 *
 * LicenseSure about accolade component.
 */

// Core dependencies
import React from "react";

// App dependencies
import Markdown from "../markdown/markdown";
class Accolade extends React.Component {
  render() {
    const { children } = this.props;
    return <Markdown>{children}</Markdown>;
  }
}

export default Accolade;
